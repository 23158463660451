.App {
  text-align: center;
  margin-top: 100px;
}

.App-logo {
  margin-left: 200px;
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App .navbar-brand {
  font-weight: bold;
  color: white;
  font-size: 32px;
  padding-left: 250px;
  height: 80px;
  vertical-align: top;
}

.App .navbar-brand>img {
  display: inline-block;
  height: 100%;
  width: auto;
  vertical-align: center;
  margin-right: 10px;
}

.App .navbar-brand:hover{
  color: white
}

.App .navbar{
  width: 100%;
  min-height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  color: white;
}

.App .navbar-nav{
  height: 80px;
  color: white;
  padding-right: 25px;
  vertical-align: center;
}

.App .navbar-nav > li > a{
  color: white;
  font-weight: bold; 
  vertical-align: center;
  line-height: 80px;
  height:80px;
}

.App .navbar-nav > li > a:hover{
  color: white;
}

.App .iconWhite{
  color: white;
}

.App .menu{
  color: yellow;
}

.navbar {
  min-height: 71px;
  padding-bottom: 10px;
}

.navbar-default {
  border: 0, 0, 1;
}

.navbar-collapse{
  min-height: 71px;
}
