body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Arial", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: url(./assets/image/register.jpeg) no-repeat center center fixed; */
  background-color: "#eeeeee";
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.Image {
    position: relative;
  }

  .ImageMobile {
    position: relative;
    height: 125px;
    padding-right: 5;
    padding-bottom: 0px;
  }


.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    background-color: brown;
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
  @keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
  
/* Example Styles for React Tags*/
#app {
    padding: 40px;
  }
  
  div.ReactTags__tags {
      position: relative;
      margin: 17px 0;
  }
  
  /* Styles for the input */
  div.ReactTags__tagInput {
      width: 200px;
      border-radius: 2px;
      display: inline-block;
  }
  ReactTags__tagInput input.ReactTags__tagInputField,
  div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
      height: 31px;
      margin: 0;
      font-size: 12px;
      width: 100%;
      border: 1px solid #eee;
      padding: 0 4px;
  }
  
  /* Styles for selected tags */
  div.ReactTags__selected span.ReactTags__tag {
      border: 1px solid #ddd;
      background: #eee;
      font-size: 12px;
      display: inline-block;
      padding: 5px;
      margin: 0 5px;
      cursor: move;
      border-radius: 2px;
  }
  div.ReactTags__selected a.ReactTags__remove {
      color: #aaa;
      margin-left: 5px;
      cursor: pointer;
  }
  
  /* Styles for suggestions */
  div.ReactTags__suggestions {
      position: absolute;
  }
  div.ReactTags__suggestions ul {
      list-style-type: none;
      box-shadow: .05em .01em .5em rgba(0,0,0,.2);
      background: white;
      width: 200px;
  }
  div.ReactTags__suggestions li {
      border-bottom: 1px solid #ddd;
      padding: 5px 10px;
      margin: 0;
  }
  div.ReactTags__suggestions li mark {
      text-decoration: underline;
      background: none;
      font-weight: 600;
  }
  div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
      background: #b7cfe0;
      cursor: pointer;
  }
.App {
  text-align: center;
  margin-top: 100px;
}

.App-logo {
  margin-left: 200px;
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.App .navbar-brand {
  font-weight: bold;
  color: white;
  font-size: 32px;
  padding-left: 250px;
  height: 80px;
  vertical-align: top;
}

.App .navbar-brand>img {
  display: inline-block;
  height: 100%;
  width: auto;
  vertical-align: center;
  margin-right: 10px;
}

.App .navbar-brand:hover{
  color: white
}

.App .navbar{
  width: 100%;
  min-height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  color: white;
}

.App .navbar-nav{
  height: 80px;
  color: white;
  padding-right: 25px;
  vertical-align: center;
}

.App .navbar-nav > li > a{
  color: white;
  font-weight: bold; 
  vertical-align: center;
  line-height: 80px;
  height:80px;
}

.App .navbar-nav > li > a:hover{
  color: white;
}

.App .iconWhite{
  color: white;
}

.App .menu{
  color: yellow;
}

.navbar {
  min-height: 71px;
  padding-bottom: 10px;
}

.navbar-default {
  border: 0, 0, 1;
}

.navbar-collapse{
  min-height: 71px;
}

